
	import Vue from "vue"
	import Component from "vue-class-component"
	import LinkButton from "@components/button/LinkButton.vue"

	import Loader from "custom/Loader.vue"

	import { EmployeeContributionType, ProductDetails as ProductModel, ProductFrameworkType } from "api/models/products"
	import { ScaleType, MaximumSalary, Franchise, SurvivorsPensionType } from "api/models/shared"

	import { productModule } from "store/modules"
	import { CoverageDefault } from "store/quotation/types"
	import { CoverageOption } from "store/agreement/constraints/types"
	import { COMPANY_ID_PARAM } from "api/models/participant"

	import { impersonate } from "../utils/impersonate"
	import userClient from "api/clients/user-client"
	import { impersonateUrl } from "api/settings"
	import { Prop } from "vue-property-decorator"

	interface MaximumSalaryValues {
		year: number
		fiscalMax: number
	}

	enum AgreementScalePercentage {
		TEN = 10,
		HUNDRED = 100
	}

	@Component({
		components: {
			Loader,
			LinkButton
		}
	})
	export default class ProductDetails extends Vue {
		@productModule.Getter product?: ProductModel
		@Prop({ type: Boolean, default: false }) showLoginBtn!: boolean

		readonly baseTranslationKey = "product.productDetails"

		readonly ScaleType = ScaleType
		readonly MaximumSalary = MaximumSalary
		readonly Franchise = Franchise
		readonly SurvivorsPensionType = SurvivorsPensionType
		readonly AgreementScalePercentage = AgreementScalePercentage
		readonly EmployeeContributionType = EmployeeContributionType

		get activeCoverageOptions(): Array<CoverageOption> {
			return this.product?.insurance?.anwGap?.coverageOptions || []
		}

		get maximumSalaryValues(): MaximumSalaryValues {
			return {
				year: new Date().getFullYear(),
				fiscalMax: this.product?.maximumSalary?.value || 0
			}
		}

		get coverageDefault(): CoverageDefault | undefined {
			return this.product?.insurance.anwGap?.coverageDefault
		}

		get agreementScalePercentage(): number {
			const rate = this.product?.scale?.utilizationRate || 0
			return rate / 10
		}

		get productType(): ProductFrameworkType {
			return this.product?.productType ?? ProductFrameworkType.WNP
		}

		get isWTP(): boolean {
			return this.productType === ProductFrameworkType.WTP
		}

		formatDecimalNumber(percentage: number): string {
			return percentage.toFixed(this.maximumDecimalPlaces(percentage) + 1).replace(".", ",").slice(0, -1)
		}

		maximumDecimalPlaces(percentage: number): number {
			if (!this.isWTP) {
				if (percentage.toString().includes(".")) {
					return Math.min(percentage.toString().split(".")[1].length, 3)
				}
				return 3
			}
			return 2
		}

		async showImpersonateDetails(): Promise<void> {
			const urlParams = new URLSearchParams(window.location.search)
			const comp: any = urlParams.get(COMPANY_ID_PARAM)
			const response = await userClient.getImpersonateTokenByCompany(comp)

			impersonate(response)
			window.open(impersonateUrl, "_blank")
		}
	}
